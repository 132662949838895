import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { FormGroup } from '@angular/forms';
import { DialogNoticeComponent } from './dialog-notice.component';
import { UnifiedOrder } from 'src/app/core/schema';
import { DialogCheckRoomNoticeComponent } from './dialog-check-room-notice.component';
import { DialogNumberPadComponent } from './dialog-number-pad.component';
import { DialogAutoNavigateComponent } from './dialog-auto-navigate.component';

export interface DialogData {
  type: 'normal' | 'action' | 'order';
  action?: () => void | Promise<void | boolean>;
  [prop: string]: any;
}

@Injectable({
  providedIn: 'root'
})
export class DialogNoticeService {

  constructor(
    private dialog: MatDialog
  ) { }

  openSimpleNoticeDialog(msg: string, msg2: string = null, timeout?: number) {
    // 다른 다이얼로그는 닫는다.
    this.dialog.closeAll();
    const dialogConfig: MatDialogConfig<DialogData> = {
      data: {
        type: 'normal',
        msg,
        msg2
      }
    };
    const dialogRef = this.dialog.open(DialogNoticeComponent, dialogConfig);

    if (timeout !== undefined) {
      setTimeout(() => {
        dialogRef.close();
      }, timeout);
    }
  }

  /**
   * '확인' 버튼과 함께 '취소' 혹은 기타 기능이 필요한 경우를 위함
   */
  openActionNoticeDialog(action: () => void, msg: string, timeout?: number) {
    this.dialog.closeAll();
    const dialogConfig: MatDialogConfig<DialogData> = {
      data: {
        type: 'action',
        action,
        msg
      },
      autoFocus: false
    };
    const dialogRef = this.dialog.open(DialogNoticeComponent, dialogConfig);

    if (timeout !== undefined) {
      setTimeout(() => {
        dialogRef.close();
      }, timeout);
    }
  }

  /**
   * 주문결과를 보여주기 위한 다이얼로그
   */
  openOrderNoticeDialog(order: Partial<UnifiedOrder> = {}, action: () => void, timeout?: number) {
    this.dialog.closeAll();
    const dialogConfig: MatDialogConfig<DialogData> = {
      data: {
        type: 'order',
        action,
        order
      },
      width: '440px',
      maxHeight: '92vh',
      autoFocus: false,
      disableClose: true
    };

    const dialogRef = this.dialog.open(DialogNoticeComponent, dialogConfig);

    if (timeout !== undefined) {
      setTimeout(() => {
        dialogRef.close();
        action();
      }, timeout);
    }
  }

  /** 전화번호 입력을 위한 다이얼로그 */
  openNumberPadDialog(action: () => Promise<void>, formGroup: FormGroup) {
    this.dialog.closeAll();

    const dialogConfig: MatDialogConfig<DialogData> = {
      data: {
        type: 'normal',
        formGroup,
        action,
      },
      width: '440px',
      disableClose: true
    };
    this.dialog.open(DialogNumberPadComponent, dialogConfig);
  }

  /** 완료된 주문에 대한 호실 번호를 알려주는 다이얼로그 */
  openCheckRoomDialog(action: () => Promise<boolean>, order: Partial<UnifiedOrder> = {}, timeout?: number) {
    this.dialog.closeAll();

    const dialogConfig: MatDialogConfig<DialogData> = {
      data: {
        type: 'action',
        order,
        action,
      },
      width: '400px',
      disableClose: true
    };
    const dialogRef = this.dialog.open(DialogCheckRoomNoticeComponent, dialogConfig);

    if (timeout !== undefined) {
      setTimeout(() => {
        dialogRef.close();
        action();
      }, timeout);
    }
  }

  /** 일정시간 입력이 없는 사용자에게 노출되며, 이 후 메인으로 이동시키는 다이얼로그 */
  openAutoNavigateDialog(action: () => void, count: number) {
    // 다른 다이얼로그는 닫는다.
    this.dialog.closeAll();
    const dialogConfig: MatDialogConfig<DialogData> = {
      data: {
        type: 'action',
        action,
        count
      }
    };
    this.dialog.open(DialogAutoNavigateComponent, dialogConfig);
  }
}
