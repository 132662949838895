import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup } from '@angular/forms';
import { DialogData } from './dialog-notice.service';

@Component({
  selector: 'app-dialog-number-pad.component',
  templateUrl: './dialog-number-pad.component.html',
  styleUrls: ['./dialog-notice.component.scss']
})
export class DialogNumberPadComponent implements OnInit {
  roomNumber: number;
  orderForm: FormGroup;
  numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9];

  constructor(
    public dialogRef: MatDialogRef<DialogNumberPadComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit(): void {
    this.orderForm = this.data.formGroup;
  }

  onOk(): void {
    this.dialogRef.close();
    this.data.action();
  }

  onCancel(): void {
    this.orderForm.get('userTel').setValue('');
    this.dialogRef.close();
  }

  onChangeDials(value: string, event: any): void {
    let userTel: string = this.orderForm.get('userTel').value;

    switch (value) {
      case '010':
        userTel += '010'; break;
      case '-':
        userTel = userTel.slice(0, -1); break;
      default:
        userTel += value;
        break;
    }

    this.orderForm.get('userTel').setValue(userTel);
    event.preventDefault();
  }
}
