import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyMaterialModule } from '../my-material/my-material.module';
import { ReactiveFormsModule } from '@angular/forms';

import { FooterComponent } from './footer/footer.component';

import { DialogNoticeComponent } from './dialog-notice/dialog-notice.component';
import { DialogSpinnerComponent } from './dialog-spinner/dialog-spinner.component';
import { DialogLoginComponent } from './dialog-login/dialog-login.component';
import { DialogOrderNoticeComponent } from './dialog-notice/dialog-order-notice.component';
import { DialogCheckRoomNoticeComponent } from './dialog-notice/dialog-check-room-notice.component';
import { MenuComponent } from './menu/menu.component';
import { DialogNumberPadComponent } from './dialog-notice/dialog-number-pad.component';
import { DialogAutoNavigateComponent } from './dialog-notice/dialog-auto-navigate.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MyMaterialModule
  ],
  declarations: [
    FooterComponent,
    MenuComponent,
    DialogNoticeComponent,
    DialogSpinnerComponent,
    DialogLoginComponent,
    DialogOrderNoticeComponent,
    DialogCheckRoomNoticeComponent,
    DialogNumberPadComponent,
    DialogAutoNavigateComponent
  ],
  exports: [
    FooterComponent,
    DialogOrderNoticeComponent,
  ],
  entryComponents: [
    DialogNoticeComponent,
    DialogSpinnerComponent,
    DialogLoginComponent,
    DialogCheckRoomNoticeComponent,
    DialogNumberPadComponent,
    DialogAutoNavigateComponent
  ]
})
export class SharedModule { }
