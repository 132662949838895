import { Component, OnInit, Inject } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material';
import { UnifiedOrder } from 'src/app/core/schema';
import { DialogData } from './dialog-notice.service';

@Component({
  selector: 'app-dialog-check-room-notice',
  templateUrl: './dialog-check-room-notice.component.html',
  styleUrls: ['./dialog-notice.component.scss']
})
export class DialogCheckRoomNoticeComponent implements OnInit {
  roomNumber: number;

  constructor(
    public dialogRef: MatDialogRef<DialogCheckRoomNoticeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData & { order: Partial<UnifiedOrder> },
  ) { }

  ngOnInit(): void {
    this.roomNumber = this.getRoomNumber(this.data.order.room);
  }

  onOk(): void {
    this.data.action();
    this.dialogRef.close();
  }

  getRoomNumber(text: string) {
    return Number(text.split('-')[2]);
  }
}
