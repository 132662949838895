import { Component, Inject } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material';
import { DialogData } from './dialog-notice.service';

@Component({
  selector: 'app-dialog-notice',
  templateUrl: './dialog-notice.component.html',
  styleUrls: ['./dialog-notice.component.scss']
})
export class DialogNoticeComponent {
  constructor(
    public dialogRef: MatDialogRef<DialogNoticeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) { }

  onOk(): void {
    if (!(this.data.type === 'normal')) {
      this.data.action();
    }
    this.dialogRef.close();
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  actionDone(result: boolean) {
    if (result) {
      if (this.dialogRef) {
        this.onOk();
        this.dialogRef = undefined;
      }
    } else {
      this.onCancel();
    }
  }
}
