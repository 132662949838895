import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/firestore';
import { environment } from '../../environments/environment';
import { BaeminUserDetailShopDoc } from './schema';
import { UtilService } from './util.service';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Subscription } from 'rxjs';

const collectionPath = environment.firestoreCollectionPath.baeminUserDetailShop;

@Injectable({
  providedIn: 'root'
})
export class BaeminUserDetailShopService {
  baeminUserDetailShop: BaeminUserDetailShopDoc[];
  latestbaeminUserDetailShopSubject = new BehaviorSubject<BaeminUserDetailShopDoc[]>([]);
  subscription: Subscription;

  constructor(
    private db: AngularFirestore,
    private utilService: UtilService
  ) { }

  observe() {
    const queryFn: QueryFn = ref => {
      const query = ref.where('_organization', '==', 'ghostkitchen').orderBy('_room', 'asc');
      return query;
    };

    const collection = this.db.collection<BaeminUserDetailShopDoc>(collectionPath, queryFn);

    this.subscription = collection.valueChanges().subscribe(doc => {
      this.baeminUserDetailShop = doc;

      this.latestbaeminUserDetailShopSubject.next(doc);
    }, error => {
      this.utilService.toastrError(`BaeminUserDetailShopService:observe에서 에러 발생 : ${error}`);
    });
  }

  /*
  observe() {
    const queryFn: QueryFn = ref => {
      const query = ref.where('_organization', '==', 'ghostkitchen').orderBy('_room', 'asc');
      return query;
    };

    const collection = this.db.collection<BaeminUserDetailShopDoc>(collectionPath, queryFn);

    // 디버깅용
    if (environment.production === false) {
      collection.stateChanges().pipe(
        map(actions => actions.map(action => {
          return { _type: action.type, ...action.payload.doc.data() };
        }))
      ).subscribe(shops => {
        for (const shop of shops) {
          // debugLog(`[${shop._id}] ${shop.data.shop_info.Shop_Nm}(${shop.data.shop_info.Shop_No})`);
        }
      });
    }

    // valueChanges는 snapshopChanges에서 metadata는 필요없고 data()만 필요한 경우에 사용한다.
    const observable = collection.valueChanges();

    return observable;
  }
   */


  observeRoom(room: string) {
    const queryFn: QueryFn = ref => {
      const query = ref.where('_room', '==', room);
      return query;
    };

    const collection = this.db.collection<BaeminUserDetailShopDoc>(collectionPath, queryFn);

    // 디버깅용
    if (environment.production === false) {
      collection.stateChanges().pipe(
        map(actions => actions.map(action => {
          return { _type: action.type, ...action.payload.doc.data() };
        }))
      ).subscribe(shops => {
        for (const shop of shops) {
          // debugLog(`[${shop._id}] ${shop.shop_info.Shop_Nm}(${shop.shop_info.Shop_No})`);
        }
      });
    }

    // valueChanges는 snapshopChanges에서 metadata는 필요없고 data()만 필요한 경우에 사용한다.
    const observable = collection.valueChanges();

    return observable;
  }

}


