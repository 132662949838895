import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { UnifiedMenuService } from './core/unified-menu.service';

/**
 * 개별 페이지를 표시하기 전에 준비해야 할 것이 있으면 기다리도록 한다.
 * - conf/organization
 */
@Injectable({
  providedIn: 'root'
})
export class MenuGuardService implements CanActivate {
  constructor(
    private unifiedMenuService: UnifiedMenuService
  ) { }

  promiseForInit(site: string) {
    return new Promise<boolean>((resolve) => {
      resolve(true);

      this.unifiedMenuService.restartObservingForSite(site);

      this.unifiedMenuService.latestUnifiedMenuSubject.subscribe(docs => {
        if (this.unifiedMenuService.unifiedMenu && docs.length > 0) {
          resolve(true);
        }
      });
    });
  }

  canActivate(route: ActivatedRouteSnapshot) {
    // debugLog(`***************** canActivate: '${route.url}'`);
    // console.dir(route);
    const site = route.paramMap.get('site');

    return this.promiseForInit(site);
  }
}

