import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject, Subscription } from 'rxjs';
import { FaceConf, BlacklistConf } from './schema';
import { UtilService } from './util.service';

const confCollectionPath = 'conf';

@Injectable({
  providedIn: 'root'
})
export class ConfService {
  faceConf: FaceConf = {
    isClosed: false,
    isTemporaryClosed: false,
    msgClosed: '서버에서 메시지 가져오는 중...',
    msgTemporaryClosed: '서버에서 메시지 가져오는 중...',
  };
  lastestFaceConfSubject = new BehaviorSubject<FaceConf>(this.faceConf);
  faceSubscription: Subscription;

  blacklistConf: BlacklistConf = {
    baeminShops: []
  };
  lastestBlacklistConfSubject = new BehaviorSubject<BlacklistConf>(this.blacklistConf);
  blacklistSubscription: Subscription;

  constructor(
    private db: AngularFirestore,
    private utilService: UtilService
  ) {
  }

  /**
   * 최신 상태를 유지하며 변화가 있으면 알려준다.
   */
  observeFace() {
    const docRef = this.db.doc<FaceConf>(`${confCollectionPath}/face`);

    this.faceSubscription = docRef.valueChanges().subscribe(doc => {
      this.faceConf = doc;
      this.lastestFaceConfSubject.next(doc);
    }, error => {
      this.utilService.toastrError(`observeFace()에서 에러 발생 : ${error}`);
    });
  }

  observeBlacklist() {
    const docRef = this.db.doc<BlacklistConf>(`${confCollectionPath}/blacklist`);

    this.blacklistSubscription = docRef.valueChanges().subscribe(doc => {
      this.blacklistConf = doc;
      this.lastestBlacklistConfSubject.next(doc);
    }, error => {
      this.utilService.toastrError(`observeBlacklist()에서 에러 발생 : ${error}`);
    });
  }
}
