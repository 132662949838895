import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription, combineLatest } from 'rxjs';
import { BaeminUserDetailShopDoc } from './schema';
import { debugLog } from './util';
import { ShopList, ShopDetail } from './schema-shop-detail';
import { UnifiedMenuService } from './unified-menu.service';
import { BaeminUserDetailShopService } from './baemin-user-detail-shop.service';
import { UtilService } from './util.service';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { SiteService } from './site.service';
import { RoomService } from './room.service';

@Injectable({
  providedIn: 'root'
})
export class ShopDetailService {
  shopDetails: ShopList[];
  latestShopDetailsSubject = new BehaviorSubject<ShopList[]>([]);
  shopDetailSubscription: Subscription;
  subscription: Subscription;

  organizationSubscription: Subscription;

  constructor(
    private baeminUserDetailShopService: BaeminUserDetailShopService,
    private unifiedMenuService: UnifiedMenuService,
    private siteService: SiteService,
    private roomService: RoomService,
    private utilService: UtilService
  ) {
  }

  observe() {
    const baeminUserDetailShopSubject = this.baeminUserDetailShopService.latestbaeminUserDetailShopSubject.pipe(
      distinctUntilChanged((prev, cur) => {
        const x = prev.map(shop => `${shop.data.shop_info.Ad_Yn}${shop.data.shop_info.Ord_Avail_Yn}`).join('');
        const y = cur.map(shop => `${shop.data.shop_info.Ad_Yn}${shop.data.shop_info.Ord_Avail_Yn}`).join('');

        return x === y;
      }),
      tap(_ => debugLog('ShopDetailService::userDetailShop emit'))
    );
    const unifiedMenuSubject = this.unifiedMenuService.latestUnifiedMenuSubject.pipe(
      tap(_ => debugLog('ShopDetailService::unifiedMenu emit'))
    );
    const siteSubject = this.siteService.latestSubject.pipe(
      tap(_ => debugLog('ShopDetailService::siteDocs emit'))
    );
    const roomSubject = this.roomService.latestSubject.pipe(
      tap(_ => debugLog('ShopDetailService::roomDocs emit'))
    );

    this.subscription = combineLatest([baeminUserDetailShopSubject, unifiedMenuSubject, siteSubject, roomSubject])
      .subscribe(([detailDocs, menuDocs, siteDocs, roomDocs]) => {
        const closedRooms = Object.values(roomDocs).filter(roomDoc => !roomDoc.live || roomDoc.virtual).map(roomDoc => roomDoc.room);

        const shopLists: ShopList[] = Object.keys(siteDocs).map(siteKey => {
          const shops: ShopDetail[] = Object.values(menuDocs).map(menuDoc => {
            const matchedDetail = detailDocs.find(detailDoc => detailDoc.data.shop_info.Shop_No === menuDoc.shopNo);

            return matchedDetail ? {
              ...menuDoc,
              _imgUrl: this.getImgUrl(matchedDetail),
              _open: !closedRooms.includes(menuDoc.room) && (matchedDetail.data.shop_info.Ad_Yn === 'Y'),
              _liveShop: (matchedDetail && matchedDetail.data) && matchedDetail.data.shop_info.Ord_Avail_Yn === 'Y' ? true : false,
              _food: (matchedDetail && matchedDetail.data) ? matchedDetail.data.shop_info.Ct_Cd_Nm : undefined
            } : undefined;
          })
          .filter(shop => shop && shop.site === siteKey && shop._open);

          return {
            name: siteKey,
            shops
          };
        });
        this.shopDetails = shopLists;
        this.latestShopDetailsSubject.next(shopLists);
      }, error => {
        this.utilService.toastrError(`ShopDetailService:observe에서 에러 발생 : ${error}`);
      }
    );
  }

  private getImgUrl(matchedDetail: BaeminUserDetailShopDoc) {
    return (matchedDetail && matchedDetail.data.shop_menu.menu_info && matchedDetail.data.shop_menu.menu_info.Shop_Header_Img_File)
        ? matchedDetail.data.shop_menu.menu_info.Shop_Header_Img_Host
          + '/' + matchedDetail.data.shop_menu.menu_info.Shop_Header_Img_Path
          + '/' + matchedDetail.data.shop_menu.menu_info.Shop_Header_Img_File
        : undefined;
  }
}
