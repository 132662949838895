import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { interval, Subscription } from 'rxjs';
import { DialogData } from './dialog-notice.service';

@Component({
  selector: 'app-dialog-auto-navigate.component',
  templateUrl: './dialog-auto-navigate.component.html',
  styleUrls: ['./dialog-notice.component.scss']
})
export class DialogAutoNavigateComponent implements OnInit, OnDestroy {
  currentSecond = 0;
  progressBarValue = 100;
  counterSubscribe: Subscription;

  constructor(
    public dialogRef: MatDialogRef<DialogAutoNavigateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit(): void {
    this.startCountdown(this.data.count);
  }

  ngOnDestroy(): void {
    if (this.counterSubscribe) {
      this.counterSubscribe.unsubscribe();
    }
  }

  close(): void {
    this.dialogRef.close();
    this.counterSubscribe.unsubscribe();
  }

  startCountdown(count: number) {
    const counter = interval(100);

    this.counterSubscribe = counter.subscribe(x => {
      this.progressBarValue = 100 - (x * 10 / count);
      this.currentSecond = x / 10.0;

      if (this.currentSecond >= count) {
        this.close();
        this.data.action();
        this.counterSubscribe.unsubscribe();
      }
    });
  }
}
