import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { DialogSpinnerComponent } from './dialog-spinner.component';

@Injectable({
  providedIn: 'root'
})
export class DialogSpinnerService {

  constructor(
    private dialog: MatDialog
  ) { }

  openSpinnerDialog(msg: string) {
    const dialogRef = this.dialog.open(DialogSpinnerComponent, {
      width: '270px',
      height: '270px',
      disableClose: true,
      panelClass: 'dialog-spinner',
      data: {
        title: msg
      }
    });

    return dialogRef;
  }
}
