import { Subscription, combineLatest } from 'rxjs';
import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Location } from '@angular/common';
import { MatRadioChange, MatCheckboxChange } from '@angular/material';

import { ConfService } from '../../core/conf.service';
import { UtilService } from '../../core/util.service';
import { postProcessFoods } from '../../core/util';
import { UnifiedMenuService } from '../../core/unified-menu.service';
import { BaeminUserDetailShopService } from '../../core/baemin-user-detail-shop.service';
import { UnifiedOrderFood, UnifiedOrderFoodOpt, UnifiedMenu } from '../../core/schema';
import { DialogNoticeService } from '../../shared/dialog-notice/dialog-notice.service';

@Component({
  selector: 'app-menu-book',
  templateUrl: './menu-book.component.html',
  styleUrls: ['./menu-book.component.scss']
})
export class MenuBookComponent implements OnInit, OnDestroy {

  /**
   * roomKey 속성이 변경이 되면 View를 다시 만들어야 한다.
   */
  private roomKey: string;
  @Input('roomKey') set _roomKey(value: string) {
    this.roomKey = value;
  }

  @Output() foodAdded = new EventEmitter<UnifiedOrderFood>();

  menuDocs: UnifiedMenu[] = [];

  subscription: Subscription;
  menuSubscripiton: Subscription;

  // 선택한 메뉴/옵션을 저장한다.
  unifiedOrderFoods: UnifiedOrderFood[] = [];
  selectedIndex = '0';

  constructor(
    private baeminUserDetailShopService: BaeminUserDetailShopService,
    private unifiedMenuService: UnifiedMenuService,
    private confService: ConfService,
    private utilService: UtilService,
    private dialogNoticeService: DialogNoticeService,
    private location: Location,
  ) { }

  ngOnInit() {
    this.initialize();
  }

  ngOnDestroy(): void {
    this.free();
  }

  initialize() {
    const baeminUserDetailShopSubject = this.baeminUserDetailShopService.latestbaeminUserDetailShopSubject;
    const unifiedMenuSubject = this.unifiedMenuService.latestUnifiedMenuSubject;
    const blacklistSubject = this.confService.lastestBlacklistConfSubject;

    this.subscription = combineLatest([baeminUserDetailShopSubject, unifiedMenuSubject, blacklistSubject])
      .subscribe(([shops, menuDocs, blacklistConf]) => {
      const filterShopNos = blacklistConf.baeminShops.map(shop => shop.shopNo);

      const menusForRoomKey = [];
      menuDocs.forEach(menuDoc => {
        if (menuDoc.room === this.roomKey) {
          menusForRoomKey.push(menuDoc);
        }
      });

      this.menuDocs = menusForRoomKey.filter(menuDoc => {
        const shopNo = menuDoc.shopNo;
        // 해당 업소 정보를 확인하기 위해서 업소를 찾는다.
        const matchingShop = shops.find(shop => shop.data.shop_info.Shop_No === shopNo);

        if (matchingShop === undefined) {
          console.error(`${shopNo}에 해당하는 shop를 baeminUserDetailShop에서 찾을 수 없습니다. 사라진 업소로 보이니 unifiedMenu에서 제거해 주세요`);
          return false;
        }

        const matchingShopData = shops.find(shop => shop.data.shop_info.Shop_No === shopNo).data;

        if (matchingShop) {
          // 광고를 하지 않으면 메뉴 정보가 없으므로 제외
          if (matchingShopData.shop_info.Ad_Yn === 'N') {
            return false;
          }

          // 장기간 휴무인 업소 등을 제거하기 위한 목록과 비교한다.
          if (filterShopNos.includes(matchingShopData.shop_info.Shop_No)) {
            return false;
          }

          // Side effect : _liveShop 설정.
          // 캘리방콕(배라)의 경우에 Live_Yn_Shop의 N인 경우에도 영업중이다.
          // menuDoc._liveShop = matchingShop.shop_info.Live_Yn_Shop === 'Y' ? true : false;
          menuDoc._liveShop = matchingShopData.shop_info.Ord_Avail_Yn === 'Y' ? true : false;
          return true;
        } else {
          this.utilService.toastrError(`${menuDoc.shopName}(${menuDoc.shopNo})에 해당하는 업소 정보가 없습니다.`);
          return false;
        }
      });

      if (this.menuDocs.length > 1) {
        this.initTab();
      }
    });
  }

  initTab() {
    const state: any = this.location.getState();

    if (state && state.shopNo) {
      this.menuDocs.forEach((menuDoc, idx) => {
        if (menuDoc.shopNo === state.shopNo) {
          this.selectedIndex = String(idx);
        }
      });
    }
  }

  free() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    // ui에 사용된 _optQty값 초기화
    this.menuDocs.forEach(menuDoc => {
      menuDoc.menus.forEach(menu => {
        menu.foods.forEach(food => {
          food.foodOptGroups.forEach(foodOptGroup => {
            foodOptGroup.foodOpts.forEach(foodOpt => {
              foodOpt._optQty = undefined;
            });
          });
        });
      });
    });
  }

  sum(shopIndex: number, foodGrpIndex: number, foodIndex: number) {
    const food = this.menuDocs[shopIndex].menus[foodGrpIndex].foods[foodIndex];

    let sum = 0;
    for (const foodOptGroup of food.foodOptGroups) {
      for (const foodOpt of foodOptGroup.foodOpts) {
        if (foodOpt._optQty > 0) {
          sum += foodOpt._optQty * foodOpt.optPrice;
        }
      }
    }

    return sum;
  }

  controlType(shopIndex: number, foodGrpIndex: number, foodIndex: number, foodOptGroupIndex: number) {
    const optGroup = this.menuDocs[shopIndex].menus[foodGrpIndex].foods[foodIndex].foodOptGroups[foodOptGroupIndex];
    const foodName = this.menuDocs[shopIndex].menus[foodGrpIndex].foods[foodIndex].foodName;
    // 모두 다 선택해야 하는 경우
    // 1. 디폴트 옵션, 보통 1개
    if (optGroup.optGrpMinSel === optGroup.foodOpts.length) {
      return 'radio';
    // 2. 필수 선택 옵션, 보통 1개
    } else if (optGroup.optGrpMinSel === 1 && optGroup.optGrpMaxSel === 1 && optGroup.foodOpts.length > 1) {
      return 'radio';
    } else {
      if (optGroup.optGrpMinSel > 0 || optGroup.optGrpMaxSel < optGroup.foodOpts.length) {
        // console.warn(`food = ${foodName}, minSel = ${optGroup.optGrpMinSel}, maxSel = ${optGroup.optGrpMaxSel}, opts.length = ${optGroup.foodOpts.length}`);
      }
      return 'checkbox';
    }
  }

  disabled(shopIndex: number, foodGrpIndex: number, foodIndex: number, foodOptGroupIndex: number, foodOptIndex: number) {
    const optGroup = this.menuDocs[shopIndex].menus[foodGrpIndex].foods[foodIndex].foodOptGroups[foodOptGroupIndex];
    const foodOpt = optGroup.foodOpts[foodOptIndex];

    // 최대 선택에 제한이 있는 경우라면 (밥투정: 고기덕후세트/공기밥추가선택)
    if (optGroup.optGrpMaxSel < optGroup.foodOpts.length) {
      const numChecked = optGroup.foodOpts.reduce((acc, $foodOpt) => acc + ($foodOpt._optQty > 0 ? 1 : 0), 0);

      // 최대 허용치에 도달했다면
      if (optGroup.optGrpMaxSel === numChecked) {
        return foodOpt._optQty > 0 ? false : true;
      } else {
        return false;
      }
    }
  }

  checked(shopIndex: number, foodGrpIndex: number, foodIndex: number, foodOptGroupIndex: number, foodOptIndex: number) {
    const optGroup = this.menuDocs[shopIndex].menus[foodGrpIndex].foods[foodIndex].foodOptGroups[foodOptGroupIndex];
    const foodOpt = optGroup.foodOpts[foodOptIndex];

    // UI 코드에서 모델을 초기화하고 있다. 별로 좋은 방법은 아니지만 여기서는 효율적이다.
    if (foodOpt._optQty === undefined) {
      if (optGroup.optGrpMinSel === optGroup.foodOpts.length) {
        foodOpt._optQty = 1;
        return true;
      }

      if (optGroup.optGrpMinSel > 0 && foodOptIndex < optGroup.optGrpMinSel) {
        foodOpt._optQty = 1;
        return true;
      }
    } else {
      return (foodOpt._optQty > 0) ? true : false;
    }
  }

  onCheckboxChange(shopIndex: number, foodGrpIndex: number, foodIndex: number, foodOptGroupIndex: number, foodOptIndex: number, $event: MatCheckboxChange) {
    // debugLog($event.checked);

    const optGroup = this.menuDocs[shopIndex].menus[foodGrpIndex].foods[foodIndex].foodOptGroups[foodOptGroupIndex];
    const foodOpt = optGroup.foodOpts[foodOptIndex];

    foodOpt._optQty = $event.checked ? 1 : 0;
  }

  onRadioChange(shopIndex: number, foodGrpIndex: number, foodIndex: number, foodOptGroupIndex: number, foodOptIndex: number, $event: MatRadioChange) {
    // debugLog($event.value);

    const optGroup = this.menuDocs[shopIndex].menus[foodGrpIndex].foods[foodIndex].foodOptGroups[foodOptGroupIndex];
    const foodOpt = optGroup.foodOpts[foodOptIndex];

    // radio인 경우에는 그룹에서 1개만 선택할 수 있으므로 나머지는 0
    for (const opt of optGroup.foodOpts) {
      opt._optQty = 0;
    }

    foodOpt._optQty = 1;
  }

  addFood(shopIndex: number, foodGrpIndex: number, foodIndex: number) {
    const food = this.menuDocs[shopIndex].menus[foodGrpIndex].foods[foodIndex];

    const liveShop = this.menuDocs[shopIndex]._liveShop;

    if (liveShop === false) {
      this.dialogNoticeService.openSimpleNoticeDialog('준비중인 업소입니다.');
      return;
    }

    const foodOpts: UnifiedOrderFoodOpt[] = [];
    for (const foodOptGroup of food.foodOptGroups) {
      for (const foodOpt of foodOptGroup.foodOpts) {
        if (foodOpt._optQty > 0) {
          foodOpts.push({
            optGrpName: foodOptGroup.optGrpName,
            optName: foodOpt.optName,
            optPrice: foodOpt.optPrice,
            optQty: foodOpt._optQty
          });
        }
      }
    }

    const unifiedOrderFood: UnifiedOrderFood = {
      foodName: food.foodName,
      foodOpts,
      foodOrdPrice: foodOpts.reduce((acc, foodOpt) => acc + foodOpt.optPrice * foodOpt.optQty, 0),
      foodQty: 1,
      mergedName: food.foodName  // 나중에 변경한다.
    };

    // 추가된 메뉴를 전달한다.
    this.foodAdded.emit(unifiedOrderFood);

    this.unifiedOrderFoods.push(unifiedOrderFood);

    this.unifiedOrderFoods = postProcessFoods(this.unifiedOrderFoods);
  }

  gotoBaemin(shopNo: string) {
    window.location.href = `https://www.baemin.com/shopDetail?shopDetail_shopNo=${shopNo}&bm_rfr=SHARE&shopDetail_categoryTypeCode=1`;
  }
}
