import fecha from 'fecha';
import { combineLatest, Subscription } from 'rxjs';
import { MatDialogRef } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, ValidatorFn, ValidationErrors } from '@angular/forms';

import { UtilService } from '../../core/util.service';
import { SiteService } from '../../core/site.service';
import { RoomService } from '../../core/room.service';
import { UnifiedOrderService } from '../../core/unified-order.service';
import { postProcessFoods, trimOrganization, trimSite } from '../../core/util';
import { UnifiedOrder, UnifiedOrderStatusCode, UnifiedOrderContextStatusCode, RoomDoc } from '../../core/schema';
import { unifiedOrderVendorMappings, unifiedOrderChannelMappings, unifiedOrderDeliveryTypeMappings } from '../../core/string-map';
import { DialogSpinnerComponent } from '../../shared/dialog-spinner/dialog-spinner.component';
import { DialogSpinnerService } from '../../shared/dialog-spinner/dialog-spinner.service';
import { DialogNoticeService } from '../../shared/dialog-notice/dialog-notice.service';
import { CanComponentDeactivate } from '../../deactivate-guard.service';
import { MenuFormComponent } from '../menu-form/menu-form.component';

@Component({
  selector: 'app-menu-book-select',
  templateUrl: './menu-book-select.component.html',
  styleUrls: ['./menu-book-select.component.scss']
})
export class MenuBookSelectComponent implements OnInit, OnDestroy, CanComponentDeactivate {
  dialogRef: MatDialogRef<DialogSpinnerComponent, any>;
  @ViewChild('menuForm', { static: false }) menuFormRef: MenuFormComponent;

  unifiedOrderVendorMappings = unifiedOrderVendorMappings;
  unifiedOrderChannelMappings = unifiedOrderChannelMappings;
  unifiedOrderDeliveryTypeMappings = unifiedOrderDeliveryTypeMappings;
  trimOrganization = trimOrganization;
  trimSite = trimSite;

  private subscription: Subscription;
  sites: {
    name: string;
    rooms: RoomDoc[];
  }[] = [];
  rooms: RoomDoc[] = [];

  roomKey: string;
  room: RoomDoc;

  siteControl: FormControl;
  roomControl: FormControl;

  // Data model
  unifiedOrder: Partial<UnifiedOrder> = {};

  // Form Control
  orderForm: FormGroup;

  // selectedIndex = 0;

  constructor(
    private route: ActivatedRoute,
    private siteService: SiteService,
    private roomService: RoomService,
    private utilService: UtilService,
    private fb: FormBuilder,
    private dialogSpinnerService: DialogSpinnerService,
    private dialogNoticeService: DialogNoticeService,
    private unifiedOrderService: UnifiedOrderService,
    private router: Router
  ) { }

  ngOnInit() {
    this.roomKey = this.route.snapshot.paramMap.get('room');

    this.initModel();
    this.initModelForRoom(this.roomKey);

    this.buildForm();

    this.observeRoom();
    this.observeUserTel();
    this.observeOrganization();
  }

  ngOnDestroy() {
    if (this.dialogRef) {
      this.dialogRef.close();
    }

    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  /**
   * room 관련 필드는 제외했다.
   */
  initModel() {
    // Data model
    this.unifiedOrder = {...this.unifiedOrder, ...{
      orderChannel: 'face',
      orderVendor: 'ghostkitchen',
      deliveryType: 'TAKEOUT',
      instanceNo: '', // 기록하지 않는다.
      orderNo: '', // createFaceOrder에서 docId로 채워주고있다.
      shopNo: '', // 기록하지 않는다.
      orderDate: fecha.format(new Date(), 'YYYY-MM-DDTHH:mm:ss+0900'), // 나중에 최종 적으로 업데이트한다.
      orderStatusCode: UnifiedOrderStatusCode.STAGING,
      contextStatusCode: UnifiedOrderContextStatusCode.STAGING,
      orderAmount: 0,
      deliveryTip: 0,
      deliveryMinutes: 0,
      paymentMethod: '후불카드',
      userTel: '',
      orderMsg: '',

      address_key: '',
      address_detail: '',
      address_sido: '',
      address_sigungu: '',
      address_dong: '',
      address_jibun: '',
      address_dongH: '',
      address_road: '',
      address_building_name: '',
      address_location: {
        lon: 0,
        lat: 0
      },
      vroong: {
        dest_sigungu: '',
        dest_legal_eupmyeondong: '',
        dest_admin_eupmyeondong: '',
        dest_ri: '',
        dest_beonji: '',
        dest_road: '',
        dest_building_number: '',
      },
      eventDiscount: 0,
      createdBy: 'face'
    }};

    this.initModelForRoom(this.roomKey);
  }

  /**
   * 최초에 한 번만 실행
   */
  buildForm() {
    this.orderForm = this.fb.group({
      userTel: [this.unifiedOrder.userTel, this.userTelValidator()],
      orderMsg: this.unifiedOrder.orderMsg,
    }, { validators: this.formValidator() }); // cross field validation : https://angular.io/guide/form-validation#cross-field-validation

    this.roomControl = new FormControl(this.roomKey);
    this.siteControl = new FormControl(this.room.siteName);
  }

  /**
   * 다른 필드는 유지하고 room 관련 필드만 변경할 때 사용한다.
   */
  initModelForRoom(roomKey: string) {
    const room = this.roomService.roomForRoomKey(roomKey);
    this.room = room;

    this.unifiedOrder.organization = room.organization;
    this.unifiedOrder.site = room.site;
    this.unifiedOrder.room = room.room;
    this.unifiedOrder.shopName = room.shopName;
  }

  /**
   * 변경된 model의 값을 적용한다.
   */
  updateForm() {
    this.orderForm.patchValue({
      userTel: this.unifiedOrder.userTel,
      orderMsg: this.unifiedOrder.orderMsg,
    });

    this.roomControl.setValue(this.roomKey, { emitEvent: false });
    this.siteControl.setValue(this.room.siteName, { emitEvent: false });
  }

  /**
   * roomKey가 변경이 되었을 때 초기화할 작업을 수행한다.
   */
  resetForRoom(roomKey: string) {
    this.roomKey = roomKey;
    this.room = this.roomService.roomForRoomKey(roomKey);

    if (this.room === undefined) {
      this.utilService.toastrError(`${roomKey}에 해당하는 room 정보를 못 찾았습니다. 문제가 있다고 개발자에게 알려주세요.`);
    }

    this.initModelForRoom(this.roomKey);
    this.updateForm();

    // 자식 component도 초기화한다.
    this.menuFormRef.initialize();
  }

  /**
   * sites와 rooms의 계층적 구조
   */
  private observeOrganization() {
    const siteSubject = this.siteService.latestSubject;
    const roomSubject = this.roomService.latestSubject;

    this.subscription = combineLatest([ siteSubject, roomSubject ]).subscribe(([ siteDocs, roomDocs ]) => {
      // 영업 시작하지 않은 경우는 제외
      const liveRooms = Object.values(roomDocs).filter(roomDoc => roomDoc.live && !roomDoc.virtual);
      const sites = Object.values(siteDocs).map(siteDoc => {
        const rooms = liveRooms.filter(room => room.siteName === siteDoc.siteName);
        return { name: siteDoc.siteName, rooms};
      });

      this.sites = sites;
      // 현재 선택된 room에 대해서 업데이트해야 한다.
      const selectedSite = this.sites.find(site => site.name === this.room.siteName);
      this.rooms = selectedSite.rooms;
    });
  }

  /**
   * UI 내용을 unifiedOrder에 반영
   */
  updateModel() {
    this.unifiedOrder = {...this.unifiedOrder, ...{
      userTel: this.orderForm.value.userTel.split('-').join(''),
      orderMsg: this.orderForm.value.orderMsg,
    }};
  }

  /**
   * 업소를 변경하면 뷰를 초기화해야 한다.
   */
  observeRoom() {
    this.siteControl.valueChanges.forEach(siteName => {
      // debugLog(`*** siteControl.valueChanges = ${siteName}`);
      const site = this.sites.find($site => $site.name === siteName);
      this.rooms = site.rooms;
      if (site) {
        this.roomControl.setValue(site.rooms[0].room);
      } else {
        console.error('No site');
      }
    });

    this.roomControl.valueChanges.forEach(roomKey => {
      // debugLog(`*** roomControl.valueChanges = ${roomKey}`);
      this.resetForRoom(roomKey);

      this.router.navigate(['menu', roomKey]);
      // 장바구니 & 주문 탭에서 메뉴 탭으로 이동하기
      // this.selectedIndex = 0;
    });
  }

  /**
   * userTel에 변화가 있으면 포맷을 자동 적용한다.
   */
  observeUserTel() {
    const formControl = this.orderForm.get('userTel');

    formControl.valueChanges.forEach(value => {
      const normalizedTel = this.normalizeTel(value);
      if (value !== normalizedTel) {
        this.orderForm.get('userTel').setValue(normalizedTel);
      }
    });
  }

  /**
   * 입력 과정 중에 자동으로 -를 붙여준다.
   * util.ts에 있는 noramlizeTel과는 쓰임이 다르다.
   */
  normalizeTel(telNo: string) {
    // 숫자 이외에는 모두 제외한다.
    telNo = telNo.replace(/[^0-9]/g, '');

    // debugLog(`1. telNo = ${telNo}`);

    if (telNo[0] !== '0') {
      return '';
    }
    // 2번째 숫자가 허용되지 않는 숫자라면 거부
    if (telNo[1] !== '1' && telNo[1] !== '2'  && telNo[1] !== '5' && telNo[1] !== '7') {
      return telNo[0];
    }
    // 010, 050, 070 이 아니고 051같은 경우는 거부
    // if ((telNo[1] === '1' || telNo[1] === '5' || telNo[1] === '7') && telNo[2] !== '0' ) {
    //   return `${telNo[0]}${telNo[1]}`;
    // }

    if (telNo.match(/^010|050|070/)) {
      // 국번이 0이나 1로 시작하지 않는다.
      if (telNo[3] === '0' || telNo[3] === '1') {
        return telNo.substr(0, 3);
      }

      if (telNo.length === 12) {
        return `${telNo.substr(0, 4)}-${telNo.substr(4, 4)}-${telNo.substr(8, 4)}`;
      } else if (telNo.length > 7) {
        return `${telNo.substr(0, 3)}-${telNo.substr(3, 4)}-${telNo.substr(7, 4)}`;
      } else if (telNo.length > 3) {
        return `${telNo.substr(0, 3)}-${telNo.substr(3, 4)}`;
      } else {
        return telNo;
      }
    } else { // 02
      // 국번이 0이나 1로 시작하지 않는다.
      if (telNo[2] === '0' || telNo[2] === '1') {
        return telNo.substr(0, 2);
      }

      if (telNo.length > 9) {
        return `${telNo.substr(0, 2)}-${telNo.substr(2, 4)}-${telNo.substr(6, 4)}`;
      } else if (telNo.length > 5) {
        return `${telNo.substr(0, 2)}-${telNo.substr(2, 3)}-${telNo.substr(5, 4)}`;
      } else if (telNo.length > 2) {
        return `${telNo.substr(0, 2)}-${telNo.substr(2, 3)}`;
      } else {
        return telNo;
      }
    }
  }

  /**
   * deliveryType의 값에 따라서 결과가 다른다.
   */
  userTelValidator(): ValidatorFn {
    return (control: FormControl): ValidationErrors | null => {
      // this.orderForm은 this가 변할 경우에 undefined가 되는 경우가 있다.
      const userTel = control.value;

      if (userTel.length > 0) {
        const match = userTel.match(/^(0[157]0[1-9]?-[1-9][0-9]{3,4}-[0-9]{4}|02-[2-9][0-9]{2,3}-[0-9]{4})$/);
        if (match === null) {
          return { reason: '전화번호가 형식에 맞지 않습니다.'};
        }
      } else {
        return { reason: '전화번호가 필요합니다.'};
      }
    };
  }

  /**
   * 모든 control이 변결될 때마다 호출된다.
   * userTel과 deliveryType의 변경때 마다 userTel의 validator를 실행시키기 위해 사용한다.
   */
  formValidator(): ValidatorFn {
    return (control: FormGroup): ValidationErrors | null => {

      const orderForm = control;
      if (orderForm) {
        // onlySelf를 false로 하면 무한반복한다.
        orderForm.get('userTel').updateValueAndValidity({onlySelf: true});
      }

      return null;
    };
  }


  order(menuForm: MenuFormComponent) {
    // 1. menuForm 확인
    if (menuForm.foods.length === 0) {
      this.dialogNoticeService.openSimpleNoticeDialog('메뉴가 비었어요.');
      return;
    }

    this.dialogNoticeService.openNumberPadDialog(() => this.createOrder(menuForm), this.orderForm);
  }

  /**
   * 완전한 unifiedOrder를 구성햐여 unifiedOrder, unifiedOrderContext에 추가한다.
   *
   * @param addressForm 주소 부분을 담담
   * @param menuForm foods를 담당
   */
  async createOrder(menuForm: MenuFormComponent) {
    this.dialogRef = this.dialogSpinnerService.openSpinnerDialog('응답 대기 중');

    this.updateModel();

    this.unifiedOrder.foods = postProcessFoods(menuForm.foods);
    this.unifiedOrder.orderAmount = menuForm.foods.reduce((sum, food) => sum + food.foodOrdPrice, 0);
    this.unifiedOrder.orderDate = fecha.format(new Date(), 'YYYY-MM-DDTHH:mm:ss+0900');

    try {
      await this.unifiedOrderService.createFaceOrder(this.unifiedOrder);

      this.dialogRef.close(false);
      this.dialogRef = undefined;

      this.dialogNoticeService.openOrderNoticeDialog(
        this.unifiedOrder,
        () => this.router.navigate([`menu/${this.room.site}`]),
        30000);

      // form을 초기화한다.
      this.initModel();
      this.resetForRoom(this.roomKey);
      // 자식 component도 초기화한다.
      this.menuFormRef.initialize();

    } catch (error) {
      this.dialogRef.close(false);
      this.dialogRef = undefined;

      this.dialogNoticeService.openSimpleNoticeDialog(`에러 : ${error.message}`);
    }
  }

  canDeactivate() {
    if (this.menuFormRef.foods.length === 0) {
      return true;
    } else {
      const site = this.room.site;
      if (site === undefined) {
        this.utilService.toastrError(`현재 room(${this.room.name})의 site를 알 수 없습니다. 개발자에게 문의해주세요.`);
        return false;
      }

      this.dialogNoticeService.openActionNoticeDialog(
        () => {
          this.resetMenuForm();
          this.router.navigate([`menu/${site}`]);
        },
        '뒤로 갈 경우\n장바구니에 담은 메뉴가 삭제됩니다.'
      );
    }
  }

  resetMenuForm() {
    this.menuFormRef.initialize();
  }
}
