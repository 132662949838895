import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, AbstractControl, FormControl } from '@angular/forms';

import { UnifiedOrderFood, RoomDoc } from '../../core/schema';
import { postProcessFoods, trimSite, debugLog } from '../../core/util';
import { DialogNoticeService } from '../../shared/dialog-notice/dialog-notice.service';

@Component({
  selector: 'app-menu-form',
  templateUrl: './menu-form.component.html',
  styleUrls: ['./menu-form.component.scss']
})
export class MenuFormComponent implements OnInit {
  trimSite = trimSite;

  // Data Model
  foods: UnifiedOrderFood[] = [];
  // Form Control
  foodsForm: FormGroup;

  // UI 표시용
  totalQty = 0;
  totalAmount = 0;

  room: RoomDoc;

  constructor(
    private fb: FormBuilder,
    private dialogNoticeService: DialogNoticeService
  ) {
    debugLog('>>> NEW MenuFormComponent');
  }

  ngOnInit() {
    this.initialize();
  }

  /**
   * 부모 component에서 초기화할 때도 사용한다.
   */
  public initialize() {
    this.foods = [];
    this.updateTotal();
    this.rebuildForm(this.foods);
  }

  get foodFormArray() {
    return this.foodsForm.get('foods') as FormArray;
  }

  formArrayFor(control: AbstractControl, name: string) {
    return control.get(name) as FormArray;
  }

  amountForFoodOpt(food: FormGroup, optIndex: number) {
    // <td *ngIf="showPrice">{{ food.foodOpts[0].optPrice * food.foodOpts[0].optQty * food.foodQty | number }}</td>
    const foodOpts = food.get('foodOpts') as FormArray;
    const foodOpt = foodOpts.at(optIndex) as FormControl;

    return foodOpt.get('optPrice').value * foodOpt.get('optQty').value * food.get('foodQty').value;
  }

  /**
   * Data model this.foods를 토대로 formGroup을 만든다.
   */
  private rebuildForm(foods: UnifiedOrderFood[]) {
    const foodForms = foods.map(food => {
      const optForms = food.foodOpts.map(foodOpt => {
        return this.fb.group({
          optGrpName: [{ value: foodOpt.optGrpName, disabled: true }],
          optName: [{ value: foodOpt.optName, disabled: false }],
          optPrice: [{ value: foodOpt.optPrice, disabled: false }],
          optQty: [{ value: foodOpt.optQty, disabled: false }]
        });
      });

      return this.fb.group({
        foodName: [{ value: food.foodName, disabled: false }],
        foodOrdPrice: [{ value: food.foodOrdPrice, disabled: true }], // foodQty까지 포함한 금액
        foodQty: [{ value: food.foodQty, disabled: false }],
        mergedName: [{ value: food.mergedName, disabled: true }],
        _uiState: [{value: food._uiState, disabled: true}],

        foodOpts: this.fb.array(optForms)
      });
    });

    this.foodsForm = this.fb.group({
      foods: this.fb.array(foodForms)
    });
  }

  private updateTotal() {
    this.totalAmount = this.foods.reduce((sum, food) => sum + food.foodOrdPrice, 0);
    this.totalQty = this.foods.reduce((sum, food) => sum + food.foodQty, 0);
  }

  public addFood(room: RoomDoc, food: UnifiedOrderFood) {
    if (this.room && this.foods.length > 0 && this.room.name !== room.name) {
      // tslint:disable-next-line: max-line-length
      this.dialogNoticeService.openSimpleNoticeDialog(`다른 업소(${trimSite(this.room.name)} ${this.room.shopName})의 주문이 장바구니에 있네요. 묶음 주문은 아직 가능하지 않습니다. 기존 주문을 삭제합니다.`);
      this.initialize();
    }

    this.room = room;

    // off all _uiState
    this.foods.forEach($food => $food._uiState = 'none');
    // 일단 'new'로 하면 postProcessFoods 내부에서 'added'로 변한다.
    this.foods.push({...food, _uiState: 'new'});

    this.foods = postProcessFoods(this.foods);

    this.updateTotal();
    this.rebuildForm(this.foods);
  }

  public appendOptionTo(foodIndex, optIndex) {
    this.foods[foodIndex].foodOpts.splice(optIndex + 1, 0, {
      // TODO: 이전 optGrpName을 따라한다. '가격'이라면 계속 '가격'이 된다. 맞는 것일까? 아니면 차라리 공백?
      optGrpName: this.foods[foodIndex].foodOpts[optIndex].optGrpName,
      optName: '',
      optPrice: 0,
      optQty: 1
    });

    this.updateTotal();
    this.rebuildForm(this.foods);
  }

  public removeOption(foodIndex, optIndex) {
    this.foods[foodIndex].foodOpts.splice(optIndex, 1);

    this.updateTotal();
    this.rebuildForm(this.foods);
  }

  public removeFood(foodIndex) {
    this.foods.splice(foodIndex, 1);

    this.updateTotal();
    this.rebuildForm(this.foods);
  }

  public increaseFoodQty(foodIndex) {
    const food = this.foods[foodIndex];

    // off all _uiState
    this.foods.forEach($food => $food._uiState = 'none');
    food._uiState = 'added';

    food.foodQty++;
    food.foodOrdPrice = food.foodOpts.reduce((sum, foodOpt) => sum + foodOpt.optQty * foodOpt.optPrice, 0) * food.foodQty;

    this.updateTotal();
    this.rebuildForm(this.foods);
  }

  public decreaseFoodQty(foodIndex) {
    const food = this.foods[foodIndex];

    this.foods.forEach($food => $food._uiState = 'none');
    food._uiState = 'added'; // UI 효과용이므로 의미는 맞지 않아도 'added'로 변경한다.

    if (food.foodQty < 2) {
      this.removeFood(foodIndex);
    } else {
      food.foodQty--;
      food.foodOrdPrice = food.foodOpts.reduce((sum, foodOpt) => sum + foodOpt.optQty * foodOpt.optPrice, 0) * food.foodQty;
    }

    this.updateTotal();
    this.rebuildForm(this.foods);
  }

  public appendEmptyFood() {
    this.foods.push({
      foodName: '아주 아주 길고 긴 메뉴',
      foodQty: 1,
      foodOrdPrice: 10000,
      mergedName: '',
      foodOpts: [{
        optGrpName: '가격',
        optName: '매운맛',
        optPrice: 10000,
        optQty: 1
      }]
    });

    this.updateTotal();
    this.rebuildForm(this.foods);
  }
}
