import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { debugLog } from '../../core/util';
import { UtilService } from '../../core/util.service';
import { ShopDetailService } from '../../core/shop-detail.service';
import { ShopDetail } from '../../core/schema-shop-detail';

@Component({
  selector: 'app-baemin-shops',
  templateUrl: './baemin-shops.component.html',
  styleUrls: ['./baemin-shops.component.scss']
})
export class BaeminShopsComponent implements OnInit, OnDestroy {
  shopDetailSubscription: Subscription;

  shops: ShopDetail[] = [];
  shopMenus: { foodName: string, imageUrl: string, price?: number }[][];

  constructor(
    private shopDetailService: ShopDetailService,
    private router: Router,
    private route: ActivatedRoute,
    private utilService: UtilService,
  ) {
    debugLog('***** BaeminShopsComponent::constructor called');

    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        debugLog('***** BaeminShopsComponent::router.events called');
        const url = event.url.split('/');
        if (url[1] === 'shops') {
          const site = url[2] ? url[2] : 'gk-kangnam';
          this.updateMenuForSite(site);
        }
      }
    });
  }

  ngOnInit() {
    {
      const site = this.route.snapshot.paramMap.get('site');
      this.updateMenuForSite(site);
    }

    this.shopDetailSubscription = this.shopDetailService.latestShopDetailsSubject.subscribe(() => {
      const site = this.route.snapshot.paramMap.get('site');
      this.updateMenuForSite(site);
    });
  }

  updateMenuForSite(site: string) {
    const shopDetails = this.shopDetailService.shopDetails;
    if (shopDetails.length === 0) {
      this.utilService.toastrError('shopDetails가 없습니다. 개발자에게 알려주세요.');
      return;
    }

    const matchingShopDetail = shopDetails.find(shopDetail => shopDetail.name === site);
    if (matchingShopDetail === undefined) {
      console.log(`selectedSite = ${site}`);
      console.dir(shopDetails);
      return;
    }

    let shops = matchingShopDetail.shops;
    if (shops.length > 0) {
      shops = shops
        .sort((a, b) => {
          // 오픈하지 않은 가게 목록을 view의 하단으로 위치시키기 위함
          return (a._liveShop === b._liveShop) ? 0 : a._liveShop ? -1 : 1;
        })
        .map(shop => {
          return { ...shop, _roomNumber: this.setRoomNumber(shop.room) };
        });

      // [shop][menu]
      const shopMenus: { foodName: string, imageUrl: string, price?: number }[][] = [];
      shops.forEach(shop => {
        // 유니크한 메뉴 목록 생성
        const foods = new Set<string>([]);
        shop.menus.forEach(menu => {
          menu.foods.forEach(food => foods.add(food.foodName));
        });
        shop._foodCount = foods.size;

        let mainMenu = [];
        if (shop.menus[0]) {
          mainMenu = shop.menus[0].foods.filter((food, index) => index < 4).map(food => {
            const price = (food.foodOptGroups[0] && food.foodOptGroups[0].foodOpts[0]).optPrice;
            return { foodName: food.foodName, imageUrl: food.imageUrl, price };
          });
        }
        shopMenus.push(mainMenu);
      });

      this.shopMenus = shopMenus;
    }
    this.shops = shops;
  }

  ngOnDestroy() {
    if (this.shopDetailSubscription) {
      this.shopDetailSubscription.unsubscribe();
    }
  }

  goToShop(shop: ShopDetail) {
    this.router.navigateByUrl(`/menu/${shop.site}/${shop.room}`, { state: { shopNo: shop.shopNo } });
  }

  setRoomNumber(text: string) {
    return Number(text.split('-')[2]);
  }
}
