import { Component, OnInit, OnDestroy, ComponentRef } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material';
import { Subscription } from 'rxjs';
import { FaceConf } from './core/schema';
import { AuthService } from './core/auth.service';
import { ConfService } from './core/conf.service';
import { VersionService } from './core/version.service';
import { LocalConfigurationService } from './core/local-configuration.service';
import { BaeminUserDetailShopService } from './core/baemin-user-detail-shop.service';
import { ShopDetailService } from './core/shop-detail.service';
import { DialogNoticeService } from './shared/dialog-notice/dialog-notice.service';
import { SiteService } from './core/site.service';
import { RoomService } from './core/room.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  loggedIn = false;
  email = '';
  myVersion: string;
  latestVersion: string;
  duration = 0;

  faceConf: FaceConf = {
    isClosed: false,
    isTemporaryClosed: false,
    msgClosed: '...',
    msgTemporaryClosed: '...'
  };
  offMsg = '정상 영업중';

  title = '고스트키친 방문 고객용 주문 단말';
  navItems = [
    {name: '강남역점', route: '/menu/gk-kangnam'},
    {name: '삼성점', route: '/menu/gk-samsung'},
    {name: '송파점', route: '/menu/gk-songpa'},
    {name: 'divider'},
  ];

  // Back Button 표시를 위한 Flag
  isMain = true;
  childComponent: ComponentRef<any>;
  currentSite: string;

  private subscription: Subscription = null;
  private versionSubscription: Subscription = null;
  private durationSubscription: Subscription = null;
  private faceSubscription: Subscription = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private authService: AuthService,
    private confService: ConfService,
    private siteService: SiteService,
    private roomService: RoomService,
    private versionService: VersionService,
    private localConfigurationService: LocalConfigurationService,
    private baeminUserDetailShopService: BaeminUserDetailShopService,
    private shopDetailService: ShopDetailService,
    private dialogNoticeService: DialogNoticeService,
  ) {
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const urls = event.urlAfterRedirects.split('/');
        this.currentSite = urls[2];

        for (const navItem of this.navItems) {
          if (navItem.route === event.urlAfterRedirects) {
            this.title = `고스트키친 - ${navItem.name}`;
            document.title = navItem.name;
            break;
          }
        }

        // URL의 깊이가 4보다 작은 경우는 site페이지(BaeminShopsComponent)로 판단한다.
        this.isMain = (urls.length < 4);
        if (!this.isMain) {
          this.title = '매장 리스트로 돌아가기';
        }
      }
    });
  }

  ngOnInit() {
    this.preventIOSBounce();
    this.checkGlobalEvent(120000);

    this.authService.signinWithEmailAndPassword('orderhere1@toe.cloud', 'B71B7852-6252-4CCA-A3DD-17C81DFF16B3').then(value => {
      if (value === true) {
        this.email = 'orderhere1@toe.cloud';

        this.confService.observeFace();
        this.confService.observeBlacklist();

        this.siteService.observe();
        this.roomService.observe();
        this.baeminUserDetailShopService.observe();
        this.shopDetailService.observe();
      }
    });

    this.myVersion = this.versionService.myVersion;
    this.latestVersion = this.versionService.latestVersion;
    this.versionSubscription = this.versionService.latestVersionSubject.subscribe(lastesVersion => {
      this.latestVersion = lastesVersion;
    });

    this.faceSubscription = this.confService.lastestFaceConfSubject.subscribe(conf => {
      this.faceConf = conf;
      this.offMsg = this.faceConf.isClosed ? this.faceConf.msgClosed :
        this.faceConf.isTemporaryClosed ? this.faceConf.msgTemporaryClosed : '정상 영업중';
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.versionSubscription) {
      this.versionSubscription.unsubscribe();
    }
    if (this.durationSubscription) {
      this.durationSubscription.unsubscribe();
    }
    if (this.faceSubscription) {
      this.faceSubscription.unsubscribe();
    }
  }

  onChangeDuration(ev: Event) {
    const newDuration = parseInt((ev.target as HTMLInputElement).value, 10);

    this.localConfigurationService.durationSubject.next(newDuration);
  }

  reload() {
    window.location.reload();
  }

  onActivate(componentRef: ComponentRef<any>) {
    this.childComponent = componentRef;
  }

  goBack() {
    this.router.navigate([`menu/${this.currentSite}`]);
  }

  preventIOSBounce() {
    document.addEventListener('touchmove', event => {
      let target = event.target;

      while (target) {
        const className = (target as any).className;
        if (className && className.indexOf('scrollable') > -1) {
          return;
        }
        target = (target as any).parentNode;
      }

      event.preventDefault();
    }, { passive: false });
  }

  /**
   * ["click", "touchmove"] 이벤트가 장기간 발생하지 않는경우 매장 선택화면으로 돌아가게한다.
   * @param ms 입력한 값만큼 사용자의 입력을 기다린다.
   *
   */
  checkGlobalEvent(ms: number) {
    const autoGoBackRootSite = () => {
        return setInterval(() => {
          if (!this.isMain) {
            const count = 5;
            this.dialog.closeAll();
            this.dialogNoticeService.openAutoNavigateDialog(
              () => {
                if ((this.childComponent as any).resetMenuForm) {
                  (this.childComponent as any).resetMenuForm();
                }
                this.router.navigateByUrl(`/menu/${this.currentSite}`);
              }, count
            );
          }
      }, ms);
    };

    let timer = autoGoBackRootSite();

    document.addEventListener('click', () => {
      if (timer) {
        clearInterval(timer);
      }
      timer = autoGoBackRootSite();
    });

    document.addEventListener('touchmove', () => {
      if (timer) {
        clearInterval(timer);
      }
      timer = autoGoBackRootSite();
    });
  }
}
