import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UnifiedOrder } from 'src/app/core/schema';
import { weekdayKR } from '../../core/util';

@Component({
  selector: 'app-dialog-order-notice',
  templateUrl: './dialog-order-notice.component.html',
  styleUrls: ['./dialog-order-notice.component.scss']
})
export class DialogOrderNoticeComponent implements OnInit {
  @Input() data: {
    order: Partial<UnifiedOrder>
  };
  @Output() actionDone = new EventEmitter<boolean>();

  order: Partial<UnifiedOrder> = {};
  orderWeekday: string;
  roomNumber: number;

  constructor() { }

  ngOnInit() {
    const order = this.data.order;

    this.roomNumber = Number(order.room.split('-')[2]);
    this.orderWeekday = weekdayKR(order.orderDate);
    this.order = order;
  }

  onOk() {
    this.actionDone.emit(true);
  }

  onCancel() {
    this.actionDone.emit(false);
  }
}
