import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MyMaterialModule } from '../my-material/my-material.module';
import { SharedModule } from '../shared/shared.module';
import { BaeminShopsComponent } from './baemin-shops/baemin-shops.component';

@NgModule({
  declarations: [
    BaeminShopsComponent
  ],
  imports: [
    CommonModule,
    MyMaterialModule,
    SharedModule,
    RouterModule
  ]
})
export class PagesModule { }
