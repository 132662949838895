import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './auth-guard.service';
import { InitGuardService } from './init-guard.service';
import { MenuGuardService } from './menu-guard.service';
import { CanDeactivateGuardService } from './deactivate-guard.service';
import { MenuBookSelectComponent } from './form/menu-book-select/menu-book-select.component';
import { BaeminShopsComponent } from './pages/baemin-shops/baemin-shops.component';

const routes: Routes = [
  {
    path: '',
    // canActivate: [AuthGuardService, InitGuardService],
    // canActivateChild: [AuthGuardService, InitGuardService],
    canActivate: [InitGuardService],
    canActivateChild: [InitGuardService],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'menu/gk-kangnam',
      },
      {
        path: 'menu/:site/:room',
        pathMatch: 'full',
        component: MenuBookSelectComponent,
        canDeactivate: [CanDeactivateGuardService],
        canActivate: [MenuGuardService],
      },
      {
        path: 'menu/:site',
        pathMatch: 'full',
        component: BaeminShopsComponent,
        canActivate: [MenuGuardService],
      },
    ]
  },
  {
    path: '**',
    redirectTo: 'menu/gk-kangnam'
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
