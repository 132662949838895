import fecha from 'fecha';
import { firestore } from 'firebase/app';
import { Injectable } from '@angular/core';
import { debugLog } from './util';
import { AngularFirestore, QueryFn } from '@angular/fire/firestore';
import { environment } from '../../environments/environment';
import { UnifiedOrder, UnifiedOrderDoc, UnifiedOrderContextStatusCode, UnifiedOrderStatusCode } from './schema';
import { map } from 'rxjs/operators';

const unifiredOrderCollectionPath = environment.firestoreCollectionPath.unifiedOrder;

@Injectable({
  providedIn: 'root'
})
export class UnifiedOrderService {
  constructor(private db: AngularFirestore) { }

  /**
   *
   * @param openHours 영업 시작 시간
   * @param openMinutes 영업 시작 분
   * @param [atDate=0] 0: 오늘(0시 기준이 아니라 영업시간기준), -1: 어제
   */
  observeOrder(openHours: number, openMinutes: number, atDate = 0, orderBy: 'asc' | 'desc' = 'asc') {
    const now = new Date();
    const nowHours = now.getHours();
    const nowMinutes = now.getMinutes();

    // 현재 시각이 오픈 이전이라면 이전 날짜에 대한 주문부터 가져온다.
    if (openHours * 60 + openMinutes > nowHours * 60 + nowMinutes) {
      atDate -= 1;
    }

    const openHHMM = String(openHours).padStart(2, '0') + ':' + String(openMinutes).padStart(2, '0');

    // orderDate: string; // "2019-04-09T18:56:20+0900",
    const atDateString = fecha.format(now.getTime() + atDate * 24 * 3600 * 1000, `YYYY-MM-DDT${openHHMM}:00+0900`);

    debugLog(`${this.constructor.name}::observeOrder from ${atDateString}`);
    const queryFn: QueryFn = ref => {
      const query1 = ref.where('organization', '==', 'ghostkitchen').orderBy('orderDate', orderBy);
      const query = orderBy === 'asc' ? query1.startAt(atDateString) : query1.endAt(atDateString);
      return query;
    };

    const orderCollection = this.db.collection<UnifiedOrderDoc>(unifiredOrderCollectionPath, queryFn);

    // 디버깅용
    if (environment.production === false) {
      orderCollection.stateChanges().pipe(
        map(actions => actions.map(action => {
          return { _type: action.type, ...action.payload.doc.data() };
        }))
      ).subscribe(orders => {
        // for (const orderBy of orders) {
        //   debugLog(`[${scooter.vendor}] ${scooter.no} '${scooter._type}'`);
        // }
      });
    }

    // valueChanges는 snapshopChanges에서 metadata는 필요없고 data()만 필요한 경우에 사용한다.
    const observable = orderCollection.valueChanges();

    return observable;
  }

  /**
   * unifiedOrder의 읿부 필드를 직접 변경한다.
   * 주로 face 주문에 대해서 처리할 때 사용한다.
   */
  async mergeOrder(order: Partial<UnifiedOrder>) {
    const id = order._id;

    if (id == null) {
      throw new Error('_id field must exist');
    }

    const docRef = this.db.firestore.collection(unifiredOrderCollectionPath).doc(id);
    const doc: Partial<UnifiedOrderDoc> = { ...order, ...{
      _timeMerge: firestore.FieldValue.serverTimestamp() as firestore.Timestamp,
    }};

    return await this.db.doc<Partial<UnifiedOrderDoc>>(docRef).set(doc, {
      merge: true
    });
  }

  createFaceOrder(order: Partial<UnifiedOrder>) {
    const docRef0 = this.db.firestore.collection(unifiredOrderCollectionPath).doc();
    const id = `face-${docRef0.id}`;

    const docRef = this.db.firestore.collection(unifiredOrderCollectionPath).doc(id);

    const doc: Partial<UnifiedOrderDoc> = {...order, ...{
      _id: id,
      _timeCreate: firestore.FieldValue.serverTimestamp() as firestore.Timestamp,
      orderNo: docRef0.id
    }};

    // console.dir(doc);
    return this.db.doc<Partial<UnifiedOrderDoc>>(docRef).set(doc);
  }

  /**
   * orderStatusCode와 contextStatusCode를 직접 변경한다.
   */
  async updateOrderStatus(order: UnifiedOrder, newStatus: UnifiedOrderStatusCode) {
    const id = order._id;

    if (id == null) {
      throw new Error('order._id field must exist');
    }

    // 1. unifiedOrder 변경
    await this.mergeOrder({
      _id: id,
      orderStatusCode: newStatus,
      contextStatusCode: newStatus as unknown as UnifiedOrderContextStatusCode
    });
  }
}
