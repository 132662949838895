import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/firestore';
import { environment } from '../../environments/environment';
import { UnifiedMenuDoc } from './schema';
import { debugLog } from './util';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Subscription } from 'rxjs';
import { UtilService } from './util.service';

const collectionPath = 'unifiedMenu';

@Injectable({
  providedIn: 'root'
})
export class UnifiedMenuService {
  currentSite: string;

  unifiedMenu: UnifiedMenuDoc[];
  latestUnifiedMenuSubject = new BehaviorSubject<UnifiedMenuDoc[]>([]);
  subscription: Subscription;

  constructor(
    private db: AngularFirestore,
    private utilService: UtilService
  ) { }

  public restartObservingForSite(site: string) {
    if (this.currentSite === site) {
      return;
    }

    this.unifiedMenu = undefined;

    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }

    this.currentSite = site;
    this.observeMenu('site', site);
  }

  /**
   * 주어진 key/value 조건에 맞는 메뉴 목록을 가져온다.
   *
   * @param key 'organization' | 'site' | 'room' | 'shopNo'
   */
  private observeMenu(key: 'organization' | 'site' | 'room' | 'shopNo', value: string) {
    debugLog(`${this.constructor.name}::observeMenu for ${key}:${value}`);
    const queryFn: QueryFn = ref => {
      const query = ref.where(key, '==', value);
      return query;
    };

    const collection = this.db.collection<UnifiedMenuDoc>(collectionPath, queryFn);

    // 디버깅용
    if (environment.production === false) {
      collection.stateChanges().pipe(
        map(actions => actions.map(action => {
          return { _type: action.type, ...action.payload.doc.data() };
        }))
      ).subscribe(menus => {
        for (const menu of menus) {
          // debugLog(`[${menu._id}] ${menu.room}/${menu.shopName}`);
        }
      });
    }

    // valueChanges는 snapshopChanges에서 metadata는 필요없고 data()만 필요한 경우에 사용한다.
    this.subscription = collection.valueChanges().subscribe(doc => {
      this.unifiedMenu = doc;
      this.latestUnifiedMenuSubject.next(doc);
    });
  }
}
